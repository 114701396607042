<template>
  <section class="boardWrap">
    <navi-bar>
      {{$t('front.common.qna')}}
    </navi-bar>
    <cs-nav-mobile />
    <div class="writebtn writebtnqna">
      <a class="btnt" @click="getAcc">{{$t('front.board.bankAccountQna')}}</a>
      <a @click="goPageByName('qnaWrite')" class="btno"><!--{{$t('front.board.qnaWrite')}}-->+</a>
    </div>
    <board-filter-mobile @onSearch="onSearch"/>
    <div class="msgwrap qnalistwrap" v-if="list">
      <template v-for="item in list" v-bind:key="item.boardIdx">
        <ul :class="item.isReadAdmin === 'Y' ? 'on': 'off'">
          <li class="msgcon">
            <a><!-- @click.prevent="onClickRead(item.boardIdx)" -->
              <em v-if="item.status === 'WAIT'" class="waiticon">{{$t('front.qnaStatus.wait')}}</em>
              <em v-if="item.status === 'ANSWER'" class="compleicon">{{$t('front.qnaStatus.answer')}}</em>
              <span class="subject">
                <em class="subjecttxt1">{{item.title}}</em>
                <em class="newicon">N</em>
              </span>
              <span class="date">
                <em class="servicename">{{$t('front.qnaTypeCategory.' + item.faqType)}}</em>
                {{dateFormatForMobile(item.regDate)}}
              </span>
            </a>
            <span class="arrow" @click="item.isContentOpen = !item.isContentOpen">
              <img src="@/assets_mobile/img/icon_top_gn.svg"  v-if="item.isContentOpen"/>
              <img src="@/assets_mobile/img/icon_bottom_gr.svg" v-if="!item.isContentOpen"/>
            </span><!-- icon_bottom_gr -->
          </li>
          <li class="qnalistread" :class="{'complet': item.status === 'ANSWER', 'progress': item.status === 'WAIT'}" v-if="item.isContentOpen">
            <p class="writing">{{item.content}}</p>
            <div class="reply" v-if="item.comment.length > 0">
              <p>{{item.comment[0].content}}</p>
            </div>
          </li>
          <!--li class="trashicon" @click.prevent="onClickRemove(item.boardIdx)"></li-->
        </ul>
      </template>
    </div>
    <!--div class="btnwrap">
      <a class="selec">계좌문의</a>
      <a @click="goPageByName('qnaWrite')" class="ok">문의작성</a>
    </div-->
    <pagination-mobile v-if="pageInfo"
                       :pageNum="pageInfo.page"
                       :pageSize="pageInfo.count_per_list"
                       :totalCount="pageInfo.tatal_list_count"
                       @goToPage="loadList()"
    />
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import PaginationMobile from '@/components/ui/PaginationMobile'
import CsNavMobile from '@/views_mobile/member/cs/nav'
import Qna from '@/views/member/cscenter/qna/list'
import BoardFilterMobile from '@/components/common/mobile/BoardFilterMobile'

export default {
  name: 'QnAList',
  components: {
    BoardFilterMobile,
    CsNavMobile,
    NaviBar,
    PaginationMobile
  },
  mixins: [Qna]
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
<style>
.morebtn {margin-bottom: 10px !important;}
.searchwrap {margin-bottom: 20px !important;}
</style>
